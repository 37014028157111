import React from 'react';

const Cursos = ({ title, content }) => {
  return (
    <div className="py-8 text-center">
      <h2 className="text-2xl font-bold">{title}</h2>
      <p>{content}</p>
    </div>
  );
};

export default Cursos;
