import React, { useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup, Circle } from 'react-leaflet';
import mapa from './mapa.png';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import escudoAguascalientes from './images/escudosgobiernos/escudoAguascalientes.jpg';
import escudoBajaCalifornia from './images/escudosgobiernos/escudoBajaCalifornia.png';
import escudoBajaCaliforniaSur from './images/escudosgobiernos/escudoBajaCaliforniaSur.png';
import escudoCampeche from './images/escudosgobiernos/escudoCampeche.png';
import escudoCdMx from './images/escudosgobiernos/escudoCdMx.png';
import escudoChiapas from './images/escudosgobiernos/escudoChiapas.png';
import escudoChihuahua from './images/escudosgobiernos/escudoChihuahua.png';
import escudoCoahuila from './images/escudosgobiernos/escudoCoahuila.png';
import escudoColima from './images/escudosgobiernos/escudoColima.png';
import escudoDurango from './images/escudosgobiernos/escudoDurango.png';
import escudoEstadoMexico from './images/escudosgobiernos/escudoEstadoMexico.png';
import escudoGuanajuato from './images/escudosgobiernos/escudoGuanajuato.png';
import escudoGuerrero from './images/escudosgobiernos/escudoGuerrero.png';
import escudoHidalgo from './images/escudosgobiernos/escudoHidalgo.png';
import escudoJalisco from './images/escudosgobiernos/escudoJalisco.png';
import escudoMichoacan from './images/escudosgobiernos/escudoMichoacan.png';
import escudoMorelos from './images/escudosgobiernos/escudoMorelos.png';
import escudoNayarit from './images/escudosgobiernos/escudoNayarit.gif';
import escudoNuevoLeon from './images/escudosgobiernos/escudoNuevoLeon.png';
import escudoOaxaca from './images/escudosgobiernos/escudoOaxaca.png';
import escudoPuebla from './images/escudosgobiernos/escudoPuebla.png';
import escudoQueretaro from './images/escudosgobiernos/escudoQueretaro.png';
import escudoQuintanaRoo from './images/escudosgobiernos/escudoQuintanaRoo.png';
import escudoSanLuisPotosi from './images/escudosgobiernos/escudoSanLuisPotosi.png';
import escudoSinaloa from './images/escudosgobiernos/escudoSinaloa.png';
import escudoSonora from './images/escudosgobiernos/escudoSonora.jpg';
import escudoTabasco from './images/escudosgobiernos/escudoTabasco.png';
import escudoTamaulipas from './images/escudosgobiernos/escudoTamaulipas.png';
import escudoTlaxcala from './images/escudosgobiernos/escudoTlaxcala.png';
import escudoVeracruz from './images/escudosgobiernos/escudoVeracruz.png';
import escudoYucatan from './images/escudosgobiernos/escudoYucatan.png';
import escudoZacatecas from './images/escudosgobiernos/escudoZacatecas.jpg';


const MyMap = () => {
  const [selectedState, setSelectedState] = useState(null);
  const [showAllMarkers, setShowAllMarkers] = useState(true);
  const [splitView, setSplitView] = useState(false);

  const estados = [
    { nombre: 'Aguascalientes', 
      serv: 'Asesoría Psicológica (emergencia en crisis) y jurídica Se enlaza con C4 de seguridad pública.',
      lugar: 'Instituto Aguascalentense de las Mujeres',
      direccion: 'Av. Manuel Gómez Morín S/N Col. Ferronales. C.P. 20180, Aguascalientes, Aguascalientes.',
      telefono: '01 449 9102128 ',
      ext: '3117, 3118',
      dias: 'Lunes a viernes',
      horario: '8:00 a 15:30 hrs',
      image: escudoAguascalientes,
      coordenadas: [21.8853, -102.2916] 
    },
    { nombre: 'Baja California',
      serv: 'Brinda atención personalizada, Psicológica y jurídica',
      lugar: 'Instituto de la Mujer para el Estado de Baja California',
      direccion: 'Blvd. Anáhuac y Calzada Independencia número 1199, CANACO Centro Cívico. 21100 Mexicali. B.C.',
      telefono: 'Mexicali: 01 686 558 6364 01 y 686 557 5495 . Tijuana: 01 664 608 0888 01 y 664 608 4044',
      ext: '103',
      dias: 'Lunes a viernes',
      horario: '8:00 a 17:00 hrs.',
      image: escudoBajaCalifornia,
      coordenadas: [30.8406, -115.2838] 
    },
    { nombre: 'Baja California Sur', 
      serv: 'Brinda atención personalizada, Psicológica y jurídica.',
      lugar: 'Instituto Sudcaliforniano de la Mujer',
      direccion: 'Antonio Rosales, esquina Aquiles Serdán, Edificio 1, Col. Centro C.P. 23000, La Paz, B.C.S.',
      telefono: '01 (612) 122 2945',
      ext: '',
      dias: 'Lunes a viernes',
      horario: '8: 00 16:00 hrs',
      image: escudoBajaCaliforniaSur,
      coordenadas: [25.6251, -111.6010] 
    },
    { nombre: 'Campeche', 
      serv: 'Denuncia de violencia, asesoría e información, asesoría jurídica y psicológica',
      lugar: 'Instituto de la Mujer del Estado de Campeche',
      direccion: 'Calle 53 entre 164, circuito baluarte, centro de justicia para las mujeres, planta alta, centro histórico, C.P. 24040, Campeche',
      telefono: '01 981 811 6086 01 y 981 811 2656 ',
      ext: '114',
      dias: 'Lunes a viernes',
      horario: '8:00 a 15:00 hrs',
      image: escudoCampeche,
      coordenadas: [19.8301, -90.5349] 
    },
    { nombre: 'Chiapas', 
      serv: 'Trabajo social, apoyo jurídico y psicológico a través de la Fiscalía de la Mujer',
      lugar: 'Secretaría para el Desarrollo y Empoderamiento de las Mujeres (SEDEM)',
      direccion: 'Blvd. Andrés Serra Rojas, Esq. con Lib. Norte S/N Anexo 1 Nivel 1 Torre Chiapas, Col. El Retiro. C.P. 29040, Tuxtla Gutiérrez, Chiapas',
      telefono: '01 (961) 26 40 639 y 01 (961) 26 40 643 y 01(96112 12 611',
      ext: '',
      dias: 'Lunes a viernes',
      horario: '8:00 a 16:00 hrs.',
      image: escudoChiapas,
      coordenadas: [16.7569, -93.1292] 
    },
    { nombre: 'Chihuahua', 
      serv: 'Apoyo psicológico y jurídico',
      lugar: 'Instituto Chihuahuense de la Mujer',
      direccion: 'Calle Primero de Mayo No. 1802, Col. Pacifico, C. P. 31020, Chihuahua, Chihuahua.',
      telefono: '01 614 429 3505',
      ext: '15311',
      dias: 'Lunes a viernes',
      horario: '8:30 a 17:00 hrs',
      image: escudoChihuahua,
      coordenadas: [28.6330, -106.0691] 
    },
    { nombre: 'Ciudad de México', 
      serv: 'A través de las 16 unidades delegacionales, brinda atención personalizada, Psicológica y jurídica.',
      lugar: 'Instituto de las Mujeres de la Ciudad de México',
      direccion: 'José María Izazaga 148, Centro, 06090 Cuauhtémoc, CDMX',
      telefono: '01 55 55 12 28 36 y Línea Mujeres56 58 11 11  (24 hrs)',
      ext: '',
      dias: 'Lunes a viernes',
      horario: '9:00 a 17:00 hrs',
      image: escudoCdMx,
      coordenadas: [19.4326, -99.1332] 
    },
    { nombre: 'Coahuila', 
      serv: '',
      lugar: '',
      direccion: '',
      telefono: '',
      ext: '',
      dias: '',
      horario: '',
      image: escudoCoahuila,
      coordenadas: [27.0587, -101.7068] 
    },
    { nombre: 'Colima', 
      serv: '',
      lugar: '',
      direccion: '',
      telefono: '',
      ext: '',
      dias: '',
      horario: '',
      image: escudoColima,
      coordenadas: [19.1223, -104.0076] 
    },
    { nombre: 'Durango', 
      serv: '',
      lugar: '',
      direccion: '',
      telefono: '',
      ext: '',
      dias: '',
      horario: '',
      image: escudoDurango,
      coordenadas: [24.0277, -104.6532] 
    },
    { nombre: 'Estado de México', 
      serv: '',
      lugar: '',
      direccion: '',
      telefono: '',
      ext: '',
      dias: '',
      horario: '',
      image: escudoEstadoMexico,
      coordenadas: [19.4969, -99.7233] 
    },
    { nombre: 'Guanajuato', 
      serv: '',
      lugar: '',
      direccion: '',
      telefono: '',
      ext: '',
      dias: '',
      horario: '',
      image: escudoGuanajuato,
      coordenadas: [21.0181, -101.2574] 
    },
    { nombre: 'Guerrero', 
      serv: '',
      lugar: '',
      direccion: '',
      telefono: '',
      ext: '',
      dias: '',
      horario: '',
      image: escudoGuerrero,
      coordenadas: [17.3616, -99.8747] 
    },
    { nombre: 'Hidalgo', 
      serv: '',
      lugar: '',
      direccion: '',
      telefono: '',
      ext: '',
      dias: '',
      horario: '',
      image: escudoHidalgo,
      coordenadas: [20.0911, -98.7624] 
    },
    { nombre: 'Jalisco', 
      serv: '',
      lugar: '',
      direccion: '',
      telefono: '',
      ext: '',
      dias: '',
      horario: '',
      image: escudoJalisco,
      coordenadas: [20.6595, -103.3494] 
    },
    { nombre: 'Michoacán', 
      serv: '',
      lugar: '',
      direccion: '',
      telefono: '',
      ext: '',
      dias: '',
      horario: '',
      image: escudoMichoacan,
      coordenadas: [19.5665, -101.7068] 
    },
    { nombre: 'Morelos', 
      serv: '',
      lugar: '',
      direccion: '',
      telefono: '',
      ext: '',
      dias: '',
      horario: '',
      image: escudoMorelos,
      coordenadas: [18.6813, -99.1013] 
    },
    { nombre: 'Nayarit', 
      serv: '',
      lugar: '',
      direccion: '',
      telefono: '',
      ext: '',
      dias: '',
      horario: '',
      image: escudoNayarit,
      coordenadas: [21.7514, -104.8455] 
    },
    { nombre: 'Nuevo León', 
      serv: '',
      lugar: '',
      direccion: '',
      telefono: '',
      ext: '',
      dias: '',
      horario: '',
      image: escudoNuevoLeon,
      coordenadas: [25.5922, -99.9962] 
    },
    { nombre: 'Oaxaca', 
      serv: '',
      lugar: '',
      direccion: '',
      telefono: '',
      ext: '',
      dias: '',
      horario: '',
      image: escudoOaxaca,
      coordenadas: [17.0732, -96.7266] 
    },
    { nombre: 'Puebla',
      serv: '',
      lugar: '',
      direccion: '',
      telefono: '',
      ext: '',
      dias: '',
      horario: '',
      image: escudoPuebla,
      coordenadas: [19.0414, -98.2063] 
    },
    { nombre: 'Querétaro', 
      serv: '',
      lugar: '',
      direccion: '',
      telefono: '',
      ext: '',
      dias: '',
      horario: '',
      image: escudoQueretaro,
      coordenadas: [20.5888, -100.3899] 
    },
    { nombre: 'Quintana Roo', 
      serv: '',
      lugar: '',
      direccion: '',
      telefono: '',
      ext: '',
      dias: '',
      horario: '',
      image: escudoQuintanaRoo,
      coordenadas: [19.1817, -88.4791] 
    },
    { nombre: 'San Luis Potosí', 
      serv: '',
      lugar: '',
      direccion: '',
      telefono: '',
      ext: '',
      dias: '',
      horario: '',
      image: escudoSanLuisPotosi,
      coordenadas: [22.1566, -100.9855] 
    },
    { nombre: 'Sinaloa', 
      serv: '',
      lugar: '',
      direccion: '',
      telefono: '',
      ext: '',
      dias: '',
      horario: '',
      image: escudoSinaloa,
      coordenadas: [25.1721, -107.4795] 
    },
    { nombre: 'Sonora', 
      serv: '',
      lugar: '',
      direccion: '',
      telefono: '',
      ext: '',
      dias: '',
      horario: '',
      image: escudoSonora,
      coordenadas: [29.2972, -110.3300] 
    },
    { nombre: 'Tabasco', 
      serv: '',
      lugar: '',
      direccion: '',
      telefono: '',
      ext: '',
      dias: '',
      horario: '',
      image: escudoTabasco,
      coordenadas: [17.8409, -92.6189] 
    },
    { nombre: 'Tamaulipas', 
      serv: '',
      lugar: '',
      direccion: '',
      telefono: '',
      ext: '',
      dias: '',
      horario: '',
      image: escudoTamaulipas,
      coordenadas: [24.2669, -98.8363] 
    },
    { nombre: 'Tlaxcala', 
      serv: '',
      lugar: '',
      direccion: '',
      telefono: '',
      ext: '',
      dias: '',
      horario: '',
      image: escudoTlaxcala,
      coordenadas: [19.3139, -98.2404] 
    },
    { nombre: 'Veracruz', 
      serv: '',
      lugar: '',
      direccion: '',
      telefono: '',
      ext: '',
      dias: '',
      horario: '',
      image: escudoVeracruz,
      coordenadas: [19.1738, -96.1342] 
    },
    { nombre: 'Yucatán', 
      serv: '',
      lugar: '',
      direccion: '',
      telefono: '',
      ext: '',
      dias: '',
      horario: '',
      image: escudoYucatan,
      coordenadas: [20.7099, -89.0943] 
    },
    { nombre: 'Zacatecas', 
      serv: '',
      lugar: '',
      direccion: '',
      telefono: '',
      ext: '',
      dias: '',
      horario: '',
      image: escudoZacatecas,
      coordenadas: [22.7709, -102.5832] 
    }
];

  const customIcon = new L.Icon({
    iconUrl: require('./images/pin-tel-emergencia.png'),
    iconSize: [25, 41], // Tamaño del ícono
    iconAnchor: [12, 41], // Punto de anclaje del ícono
    popupAnchor: [1, -34], // Punto de anclaje del popup
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'), // Sombra
    shadowSize: [41, 41], // Tamaño de la sombra
  });

  const handleStateChange = (event) => {
    const stateName = event.target.value;
    const selected = estados.find((estado) => estado.nombre === stateName);
    setSelectedState(selected);
    setShowAllMarkers(false); // Ocultar marcadores adicionales si es necesario
  };

const handleViewInfo = (estado) => {
  console.log("Estado seleccionado:", estado);
  setSelectedState(estado); // Establecer el estado seleccionado
  setSplitView(true);       // Activar la vista dividida
};

console.log(selectedState);

  return (
    <div className={`map-container ${splitView ? 'grid grid-cols-2' : 'grid-cols-1'} mt-6`}>
      {/* Sección izquierda: Mapa */}
      <div className="map-section">
        <aside className='grid lg:grid-cols-2 grid-cols-1 gap-2 p-2 bg-white'>
            <left className="my-2 ml-4" >
                <p>
                    SELECCIONA EL ESTADO
                </p>
            </left>
            <div className="items-center flex">
                <select id="category" className="border border-orange-500 rounded-lg focus:ring-primary-500 focus:border-orange-900 block w-full light:bg-orange-600 dark:border-orange-500 light:placeholder-orange-400 dark:text-black dark:focus:ring-primary-500 dark:focus:border-primary-500 py-0.5 px-2" onChange={handleStateChange}>
                        <option selected="" className="items-center flex my-2">Selecciona tu zona</option>
                        {estados.map((estado, index) => (
                            <option key={index} value={estado.nombre}>{estado.nombre}</option>
                        ))}
                </select>
            </div>
        </aside>

        <MapContainer
          center={[23.6345, -102.5528]}
          zoom={5}
          style={{ height: '600px', width: '100%' }}
        >
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

          {showAllMarkers && estados.map((estado, index) => (
            <Marker key={index} position={estado.coordenadas} icon={customIcon}>
              <Popup>
                <div>
                  <p className="text-violet-600">{estado.nombre}</p>

                  <p>Conoce los números de emergencia de  {estado.nombre} </p>

                  <button
                    className="rounded-lg bg-violet-400 text-white text-center text-md p-2"
                    onClick={() => handleViewInfo(estado)}
                  >
                    Ver información <i className="fa-solid fa-chevron-right"></i>
                  </button>
                </div>
              </Popup>
            </Marker>
          ))}

            {selectedState && (
                <Marker 
                    position={selectedState.coordenadas} 
                    icon={customIcon}
                >   
                    <Popup>
                    <div>
                        <p className="text-violet-600">{selectedState.nombre}</p>

                        <p>Conoce los números de emergencia de {selectedState.nombre} </p>

                        <button 
                            className="rounded-lg bg-violet-400 text-white text-center text-md p-2" 
                            onClick={() => handleViewInfo(selectedState)}
                        >
                            Ver información <i className="fa-solid fa-chevron-right"></i>
                        </button>
                    </div>
                </Popup>
                </Marker>
            )}

            {selectedState && (
                <Circle 
                    center={[selectedState.coordenadas[0], selectedState.coordenadas[1]]}
                    radius={50000}
                    fillColor='transparent'
                    fillOpacity={0}
                    strokeColor='orange'
                    strokeWidth={3}
                />
            )}
        </MapContainer>
      </div>

      {/* Sección derecha: Información */}
      {splitView && selectedState && (
        <div className="info-section rounded-lg p-4 space-y-2" style={ {background: '#E6E6FA'} }>
          {/* Título centrado */}
          <h1 className="font-bold font-serif text-center text-lg p-4">
            LÍNEAS TELEFÓNICAS E INSTITUTOS ESTATALES DE ATENCIÓN A LA VIOLENCIA CONTRA LAS MUJERES
          </h1>
          <h2 className="text-2xl font-bold text-violet-600">{selectedState.nombre}</h2>

          {/* Contenedor flexible para imagen y texto */}
          <div className="flex items-center justify-center">
            {/* Texto en el lado derecho, ocupando el resto del espacio */}
              <div className="w-3/4 pl-6 pr-6 mt-8">
                <ul className="list-disc text-left pl-5 mt-4 text-black font-medium items-center">
                  <li className='text-base text-justify font-serif font-semibold mb-3'>
                    {selectedState.serv || "Información no disponible"}
                  </li>

                  <li className='text-lg text-justify font-serif font-semibold'>
                    Institución encargada de la linea:
                  </li>
                  <p className='text-base text-justify font-serif font-medium'>
                    {selectedState.lugar}
                  </p>

                  <li className='text-lg text-justify font-serif font-semibold'>
                    Dirección:
                  </li>
                  <p className='text-base text-justify font-serif font-medium'>
                    {selectedState.direccion || "Información no disponible"}
                  </p>

                  <li className='text-lg text-justify font-serif font-bold'>
                    Número de emergencia:
                  </li>
                  <p className='text-lg text-justify font-serif font-medium'>
                    {selectedState.telefono || "Información no disponible"}
                  </p>

                  <li className='text-lg text-justify font-serif font-semibold'>
                    Extensiones:
                  </li>
                  <p className='text-base text-justify font-serif font-medium'>
                    {selectedState.ext || "Información no disponible"}
                  </p>

                  <li className='text-lg text-justify font-serif font-semibold'>
                    Horario de atención:
                  </li>
                  <p className='text-base text-justify font-serif font-medium'>
                    De {selectedState.dias} de {selectedState.horario}
                  </p>
                </ul>

                <button
                  className="mt-4 px-4 py-2 bg-violet-400 text-white rounded-lg"
                  onClick={() => setSplitView(false)} // Cerrar vista dividida
                >
                  Cerrar
                </button>
              </div>

              {/* Imagen en el lado izquierdo, ocupando un tercio */}
              <div className="w-1/4 flex items-start justify-center ">
                  <img src={selectedState.image} alt="escudo" className="w-full h-full mt-2 ml-2 mr-2 mb-2" />
              </div>              
          </div>
        </div>
        
      )}
    </div>

  );
};

export default MyMap;
