import React, { useState } from 'react';

const Footer = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const [showInfo, setShowInfo] = useState(false);

  const toggleInfo = () => setShowInfo((prev) => !prev);

  return (
    <footer id="contacto" className="p-8 pt-10 font-sans" style={{ backgroundColor: '#cbc0d3' }}>
      <b className='mb-20 text-gray-500 '>Tu apoyo puede cambiar vidas.</b>
      <p className='mb-20'>Descubre cómo puedes contribuir a nuestra causa, ya sea a través de donaciones o convirtiéndote en voluntaria para ayudar a otras mujeres a encontrar su camino hacia una vida libre de violencia.</p>

      <div className="flex justify-between items-center">
  
        <div className="flex space-x-4">
          <a href="https://www.facebook.com/caminemosjuntasmx" className="text-blue-500" aria-label="Facebook">
            <i className="fab fa-facebook"></i>
          </a>
          <a  href="https://twitter.com/caminemosjuntasmx" className="text-blue-500" aria-label="Twitter">
            <i className="fab fa-twitter"></i>
          </a>
          <a href="https://www.tiktok.com/@caminemosjuntasmx"className="text-blue-500" aria-label="TikTok">
            <i className="fab fa-tiktok"></i>
          </a>
          <a href="https://wa.me/2223538675" className="text-blue-500" aria-label="WhatsApp">
            <i className="fab fa-whatsapp"></i>
          </a>
          <a href="https://www.instagram.com/caminemosjuntasmx" className="text-blue-500" aria-label="Instagram">
            <i className="fab fa-instagram"></i>
          </a>
        </div>

        {/* Formulario de Contacto */}
        <div>
      {/* Botón para abrir el modal */}
      <button
        type="button"
        className="rounded-lg px-4 ml-2"
        style={{ backgroundColor: '#dee2ff', color: '#8e9aaf' }}
        onClick={openModal}
      >
        Quiero unirme o donar
      </button>

      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 text-gray-500 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg shadow-lg p-6 w-96">
            
            <p className="mb-4">
              ¿Te gustaria formar parte de este gran proyecto?

            </p>
            <button
              className="w-full bg-blue-500 text-gray-900 py-2 px-4 rounded-lg mb-2"
              style={{ backgroundColor: '#dee2ff', color: '#8e9aaf' }}
              onClick={() => window.open('https://wa.me/2223538675?text=Hola%20me%20gustaria%20formar%20parte%20del%20equipo', '_blank')}
            >
              Quiero unirme
            </button>
            <button
              className="w-full bg-blue-500 text-gray-900 py-2 px-4 rounded-lg"
              style={{ backgroundColor: '#dee2ff', color: '#8e9aaf' }}
              onClick={toggleInfo}
              >
              Quiero donar
            </button>
            {showInfo && (
              <div className="mt-4 bg-white p-4 rounded-lg shadow-lg">
                <h2 className="text-xl font-semibold mb-4 text-center">Información de Donación</h2>
                <p className="">
                  <strong>Número de cuenta:</strong> 5101256458389298
                </p>
                <p className="my-2">
                  <strong>A nombre de:</strong> Cecilia Herrera
                </p>
                <p className="">
                  <strong>Concepto:</strong> Donación a Caminemos Juntas
                </p>
                <p className="mb-4">
                  Contactar a la administradora y creadora del sitio:
                </p>
                <button
                  className="w-full bg-blue-500 text-white py-2 px-4 rounded-lg"
                  onClick={() => window.open('https://wa.me/2223538675?text=Hola%20me%20gustaria%20donar', '_blank')}
                >
                  Contactar
                </button>
              </div>
            )}

            {/* Botón para cerrar el modal */}
            <button
              className="w-full mt-4 bg-gray-200 py-2 px-4 rounded-lg text-gray-600 hover:bg-gray-300"
              onClick={closeModal}
            >
              Cerrar
            </button>
          </div>
        </div>
      )}
    </div>
      </div>
    </footer>
  );
};

export default Footer;
