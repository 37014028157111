import React, { useState } from "react";
import caminemosjuntas from './images/caminemosjuntas.jpeg';


const Banner = () => {

  const [openModal, setOpenModal] = useState(null);

  const handleOpenModal = (modal) => setOpenModal(modal);
  const handleCloseModal = () => setOpenModal(null);

  return (
<div className='font-sans'>
  <div className="relative text-center group overflow-hidden">
    
    <div className='fixed top-0 right-0 left-0 flex justify-end py-2 text-black bg-opacity-50 bg-black z-10 font-sans'>
      <a href="#conoceme" className="hover:bg-purple-100 hover:text-gray-900 rounded-lg px-2 py-2 text-sm text-white">¿Quienes somos?</a>
      <a href="#quehacemos" className="hover:bg-purple-100 hover:text-gray-900 rounded-lg px-2 py-2 text-sm text-white">¿Que hacemos?</a>
      <a href="#contacto" className="hover:bg-purple-100 hover:text-gray-900 rounded-lg px-2 py-2 text-sm text-white">Contacto</a>
      <a href="#apoyo-legal" className="hover:bg-purple-100 hover:text-gray-900 rounded-lg px-2 py-2 text-sm text-white">Apoyo Psicologico/Legal</a>
    </div>
    
    <img className='w-full h-[600px] object-cover object-top rounded-b-3xl blur' src={caminemosjuntas} alt="Poder Femenino" />
    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white group-hover: rounded-b-3xl z-10" style={{fontFamily: 'Poppins, sans-serif' }}>
      <h1 className="text-5xl font-extrabold font-sans">¡Caminemos Juntas!</h1>
      <p className="text-lg font-sans">No estás sola, estamos aquí para ayudarte</p>
      <div className="mt-4 font-sans">
        <button className="bg-orange-500 text-white py-2 px-4 rounded-lg m-2"                 
          onClick={() => handleOpenModal("solicitarAyuda")}
          >
            Solicitar ayuda
          </button>
          <button
                className="bg-red-500 text-white py-2 px-4 rounded-lg m-2"
                onClick={() => handleOpenModal("contactoEmergencia")}
            >
                Contacto de emergencia
            </button>
            <button
                className="bg-teal-500 text-white py-2 px-4 rounded-lg m-2"
                onClick={() => handleOpenModal("verServicios")}
            >
                Ver servicios
            </button>
            {openModal === "solicitarAyuda" && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white rounded-lg p-4 shadow-lg max-w-sm w-full">
                        <h2 className="text-xl font-bold mb-2">Solicitar Ayuda</h2>
                        <p className="mb-4 text-gray-500">
                            <strong>Abogada Gabriela Sarabia</strong>
                            <br />
                            Teléfono: 953-177-6747
                        </p>
                        <p className="mb-4 text-gray-500">
                            <strong>Psicóloga Diana Rodríguez</strong>
                            <br />
                            Teléfono: 222-113-3330
                        </p>
                        <button
                            className="py-2 px-4  bg-black rounded-lg hover:bg-gray-300"
                            onClick={handleCloseModal}
                        >
                            Cerrar
                        </button>
                    </div>
                </div>
            )}

            {openModal === "contactoEmergencia" && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white rounded-lg p-4 shadow-lg max-w-sm w-full">
                        <h2 className="text-xl font-bold mb-2">Contacto de Emergencia</h2>
                        <ul className="list-disc list-inside mb-4 text-gray-500">
                            <li>911 - Emergencias Generales</li>
                            <li>800 800 8000 - Apoyo Psicológico</li>
                            <li>800 123 1234 - Línea de Violencia</li>
                            <li>555 555 5555 - Centro de Apoyo</li>
                        </ul>
                        <button
                            className="py-2 px-4  bg-black rounded-lg hover:bg-gray-300"
                            onClick={handleCloseModal}
                        >
                            Cerrar
                        </button>
                    </div>
                </div>
            )}

            {openModal === "verServicios" && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white rounded-lg p-4 shadow-lg max-w-sm w-full">
                        <h2 className="text-xl font-bold mb-2">Servicios Disponibles</h2>
                        <ul className="list-disc list-inside mb-4 text-gray-500">
                            <li>Asistente virtual 24/7</li>
                            <li>Chat en vivo con profesionales</li>
                            <li>Asesoría legal</li>
                            <li>Asesoría psicológica</li>
                        </ul>
                        <button
                            className="py-2 px-4  bg-black rounded-lg hover:bg-gray-300"
                            onClick={handleCloseModal}
                        >
                            Cerrar
                        </button>
                    </div>
                </div>
            )}
        
            
      </div>
      
    </div>
  </div>
  <div className='bg-gradient-to-b from-purple-200 py-10'></div>
</div>

  );
};

export default Banner;
