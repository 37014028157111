import React, { useState } from "react";
import violentometro from './images/violentometro.png';
import derechoslegales from './images/derechoslegales.jpeg';
import apoyopsicologico from './images/apoyopsicologico.jpeg';
import violenciafisica from './images/violenciafisica.avif';
import violenciaPsicologica from './images/violenciaPsicologica.jpeg';
import ViolenciaSexual from './images/ViolenciaSexual.jpeg';
import ViolenciaEconomica from './images/ViolenciaEconomica.jpeg';
import ViolenciaSimbolica from './images/ViolenciaSimbolica.jpeg';
import ViolenciaPatrimonial from './images/ViolenciaPatrimonial.avif';
import { useNavigate } from 'react-router-dom';


const SoporteLegal = ({ title, content }) => {

    const navigate = useNavigate();

    const handleRedirect = () => {
        navigate('/test');
    }

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpen1, setIsModalOpen1] = useState(false);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);
    const openModal1 = () => setIsModalOpen1(true);
    const closeModal1 = () => setIsModalOpen1(false);

    const whatsappUrl1 = "https://wa.me/522223538675?text=Hola%20Psicologa%20Diana%20BRodríguez,%20necesito%20asesoría.";
    const whatsappUrl = "https://wa.me/529532122212?text=Hola%20Licenciada%20Gabriela%20Sarabia,%20necesito%20asesoría.";


  return (
    <div id="apoyo-legal" className="text-center font-sans" style={{ backgroundColor: '#f2e9e4', color: '#000' }}>
        
        <section className="bg-white text-gray-800 p-8" style={{ backgroundColor: '#f2e9e4', color: '#000' }}>
        <section className="bg-white text-gray-800 p-8" style={{ backgroundColor: '#f2e9e4', color: '#000' }}>
            <h2 className="text-2xl font-bold mb-8 text-center">Estamos contigo</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8 font-sans">
                {/* Acompañamiento Psicosocial */}
                <div className="bg-black p-6 rounded-lg shadow-md text-white">
                <h3 className="text-xl font-semibold mb-2">Acompañamiento Psicosocial</h3>
                <p className="mb-4">Consultas psicológicas individuales y grupales para mujeres víctimas.</p>
                <button 
                    className="py-2 px-4 rounded-lg w-full" 
                    style={{ backgroundColor: '#f2e9e4', color: '#000' }}
                    onClick={openModal1}
                >
                    Agendar una cita
                </button>
                {isModalOpen1 && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white rounded-lg p-4 shadow-lg max-w-sm w-full">
                        <h2 className="text-xl font-bold mb-2">Contacto</h2>
                        <p className="mb-4 text-gray-500">
                            <strong>Psicologa Diana Rodríguez</strong>
                            <br />
                            Teléfono: 2221133330
                        </p>
                        <div className="flex justify-center text-center mb-2">
                            <a
                                href={whatsappUrl1}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="py-2 px-4 bg-green-500 text-white rounded-lg hover:bg-green-600"
                            >
                                Contactarla por WhatsApp
                            </a>
                        </div>
                        <button
                            className="py-2 px-4 bg-black rounded-lg hover:bg-gray-300 text-gray-200"
                            onClick={closeModal1}
                        >
                            Cerrar
                        </button>
                    </div>
                </div>
            )}
                </div>

                {/* Asesoría Jurídica */}
                <div className="bg-black p-6 rounded-lg shadow-md text-white font-sans">
                <h3 className="text-xl font-semibold mb-2">Asesoría Jurídica</h3>
                <p className="mb-4">Asesoría legal gratuita sobre derechos y pasos a seguir para denunciar.</p>
                <button 
                    className="py-2 px-4 rounded-lg w-full mt-1" 
                    style={{ backgroundColor: '#f2e9e4', color: '#000' }}
                    onClick={openModal}
                >
                    Necesito asesoría
                </button>
                {isModalOpen && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white rounded-lg p-4 shadow-lg max-w-sm w-full">
                        <h2 className="text-xl font-bold mb-2">Contacto</h2>
                        <p className="mb-4 text-gray-500">
                            <strong>Abogada Gabriela Sarabia</strong>
                            <br />
                            Teléfono: 9531776747
                        </p>
                        <div className="flex justify-center text-center mb-2">
                            <a
                                href={whatsappUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="py-2 px-4 bg-green-500 text-white rounded-lg hover:bg-green-600"
                            >
                                Contactarla por WhatsApp
                            </a>
                        </div>
                        <button
                            className="py-2 px-4 bg-black rounded-lg hover:bg-gray-300 text-gray-200"
                            onClick={closeModal}
                        >
                            Cerrar
                        </button>
                    </div>
                </div>
            )}
                </div>

                {/* Red de Apoyo */}
                <div className="bg-black p-6 rounded-lg shadow-md text-white font-sans">
                <h3 className="text-xl font-semibold mb-2">Red de Apoyo</h3>
                <p className="mb-4">Asistente virtual 24/7 y chat en vivo con profesionales.</p>
                <button 
                    className="py-2 px-4 rounded-lg w-full mt-6" 
                    style={{ backgroundColor: '#f2e9e4', color: '#000' }}
                    
                >
                    Ir al chat
                </button>
                </div>
            </div>
            </section>

            
                <div className="mt-20">
                    {/* Título centrado */}
                    <h1 className="text-3xl text-center mb-4 text-gray-500">¿Cómo reconocer y denunciar la violencia de género?</h1>
                    

                    <p className='text-lg font-medium items-center'>
                        El Violentómetro es una herramienta creada para identificar y prevenir diferentes formas de violencia, especialmente en relaciones de pareja, pero también en ámbitos laborales, educativos y familiares.
                    </p>

                    <h1 className="text-2xl text-center my-10">
                        Test para saber en que etapa del violentometro estás 
                        <p className="text-xl font-semibold">Da clic Aquí &emsp;
                            <button className="px-3 py-1 text-white bg-violet-400 rounded-xl" onClick={handleRedirect} >
                                <i class="fa-solid fa-chevron-right"></i>
                            </button>
                        </p>
                    </h1>

                    <div className=" items-center flex justify-center ">
                        <img src={violentometro} alt="Violentómetro" className="w-80" />
                    </div>
                        
                </div>

            <section className="bg-white text-gray-800 p-8 text-justify" style={{ backgroundColor: '#f2e9e4', color: '#000' }}>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 p-6">
                    <div className="bg-white rounded-lg shadow-lg p-6">
                        <h3 className="text-xl font-semibold mb-4">Violencia Física</h3>
                        <img src={violenciafisica} alt="Violencia Física" className="w-full h-32 object-cover mb-4" />
                        <p>Golpes, empujones, agresiones que causan daño corporal.</p>
                    </div>

                    <div className="bg-white rounded-lg shadow-lg p-6">
                        <h3 className="text-xl font-semibold mb-4">Violencia Psicológica</h3>
                        <img src={violenciaPsicologica} alt="Violencia Psicológica" className="w-full h-32 object-cover mb-4" />
                        <p>Humillaciones, insultos, manipulación, aislamiento, control emocional.</p>
                    </div>

                    <div className="bg-white rounded-lg shadow-lg p-6">
                        <h3 className="text-xl font-semibold mb-4">Violencia Sexual</h3>
                        <img src={ViolenciaSexual} alt="Violencia Sexual" className="w-full h-32 object-cover mb-4" />
                        <p>Abuso, violación, acoso sexual, explotación sexual.</p>
                    </div>

                    <div className="bg-white rounded-lg shadow-lg p-6">
                        <h3 className="text-xl font-semibold mb-4">Violencia Económica</h3>
                        <img src={ViolenciaEconomica} alt="Violencia Económica" className="w-full h-32 object-cover mb-4" />
                        <p>Control de los recursos financieros, limitación del acceso al trabajo, privación económica.</p>
                    </div>

                    <div className="bg-white rounded-lg shadow-lg p-6">
                        <h3 className="text-xl font-semibold mb-4">Violencia Simbólica</h3>
                        <img src={ViolenciaSimbolica} alt="Violencia Simbólica" className="w-full h-32 object-cover mb-4" />
                        <p>Representaciones y actitudes en medios y cultura que refuerzan la desigualdad de género.</p>
                    </div>

                    <div className="bg-white rounded-lg shadow-lg p-6">
                        <h3 className="text-xl font-semibold mb-4">Violencia Patrimonial</h3>
                        <img src={ViolenciaPatrimonial} alt="Violencia Patrimonial" className="w-full h-32 object-cover mb-4" />
                        <p>Robo, destrucción de objetos o bienes que pertenecen a la víctima.</p>
                    </div>
                </div>


            </section>
        </section>
    </div>
  );
};

export default SoporteLegal;
