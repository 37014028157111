import React, { useState, useEffect } from 'react';
import imagen from './images/test/Imagen1.png';
import imagen2 from './images/test/Imagen2.png';
import imagen3 from './images/test/Imagen3.jpg';
import imagen4 from './images/test/Imagen4.jpg';
import imagen5 from './images/test/Imagen5.jpg';
import imagen6 from './images/test/Imagen6.jpg';
import imagen7 from './images/test/Imagen7.jpg';

const AnimacionTest = () => {
  const datosCarruselLegal = [
    {
      imagen: imagen2,
      texto: "Proporcionamos asesoramiento legal para ayudar a las personas a navegar el sistema judicial."
    },
    // {
    //   imagen: imagen5,
    //   texto: "Ofrecemos refugios seguros para quienes están en situación de riesgo."
    // },
    {
      imagen: imagen,
      texto: "Implementamos programas de capacitación y empoderamiento para fortalecer a las comunidades."
    },
    {
    imagen: imagen3,
    texto: "Implementamos programas de capacitación y empoderamiento para fortalecer a las comunidades."
    },
    {
    imagen: imagen6,
    texto: "Implementamos programas de capacitación y empoderamiento para fortalecer a las comunidades."
    },
    {
    imagen: imagen7,
    texto: "Implementamos programas de capacitación y empoderamiento para fortalecer a las comunidades."
    },
    {
    imagen: imagen4,
    texto: "Implementamos programas de capacitación y empoderamiento para fortalecer a las comunidades."
    }
  ];

  return (
    <div className="flex flex-col items-center justify-center p-1 font-sans">
      <div className="max-w-lg w-full" >
        <TextoCarrusel datos={datosCarruselLegal} />
      </div>
    </div>
  );
};

const TextoCarrusel = ({ datos }) => {
    const [indiceActual, setIndiceActual] = useState(0);
  
    useEffect(() => {
      const intervalo = setInterval(() => {
        setIndiceActual((prevIndice) => (prevIndice + 1) % datos.length);
      }, 5000); // Cambia la diapositiva cada 5 segundos
  
      return () => clearInterval(intervalo); // Limpiar el intervalo al desmontar el componente
    }, [datos]);
  
    const siguienteTexto = () => {
      setIndiceActual((prevIndice) => (prevIndice + 1) % datos.length);
    };
  
    const textoAnterior = () => {
      setIndiceActual((prevIndice) => (prevIndice - 1 + datos.length) % datos.length);
    };
  
    return (
      <div className="flex flex-col items-center justify-center bg-fuchsia-200 p-1 border-dashed border-2 border-purple-600 rounded-xl">
        
        <img src={datos[indiceActual].imagen} alt={`Sección ${indiceActual + 1}`} className="w-80 h-80 mb-4" />
        <p className='text-base font-medium'>{datos[indiceActual].texto}</p>
      </div>
    );
  };

export default AnimacionTest;
