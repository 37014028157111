import React from 'react';
import SoportePsicologico from './SoportePsicologico';

const QuienesSomos = () => {
  return (
    <div >
      <p>
        
        <section id="quehacemos" className='w-auto min-h-auto mt-2 grid lg:grid-cols-2 grid-cols-1 [&>*:last-chils]:col-span-9'>
          <aside className="flex flex-col  space-y-4 ">
          <b className='ml-7 text-2xl font-serif mt-4 text-gray-500'>Bienvenida a un espacio seguro</b>
            <div className="flex items-center mt-5 ml-8">
              <p className={'text-md text-justify font-thin  text-gray-900 flex items-center justify-center'}>
                Nuestro proposito es crear un ambiente positivo y de empoderamiento, ayudando a las mujeres a sentirse valoradas y 
                apoyadas.
              </p>
            </div>
            
            <div className="flex items-center ml-8 "> 
              <p className={'text-md font-thin text-gray-900 text-justify flex items-center justify-center'}>
                Si estás en peligro inmediato, por favor llama al 911. Aquí encontrarás recursos y apoyo para ayudarte a salir de 
                situaciones de violencia. No estás sola, estamos aquí para ti.
              </p>
            </div>
            
          </aside>

          <aside>
            <br /> <SoportePsicologico title="Algunas cosas que puedes hacer para" content="Frenar la violencia de género" />
          </aside>
        </section>
      </p>
    </div>
  );
};

export default QuienesSomos;
