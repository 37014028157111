import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import cami from './images/chatbots.png';
import faqs from './faqs';

function ChatPopup() {
    const [name, setName] = useState(''); // Nombre del usuario
    const [message, setMessage] = useState('');
    const [messages, setMessages] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [quickReplies, setQuickReplies] = useState([]); // Opciones rápidas de respuesta
    const messagesEndRef = useRef(null);

    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }, [messages, quickReplies]);

    useEffect(() => {
        if (isOpen) {
            setMessages([{ sender: 'Cami', text: 'Hola, soy Cami, tu asistente. ¿Cómo puedo ayudarte?' }]);
            setQuickReplies([]); // Resetea las respuestas rápidas cuando se abre
        }
    }, [isOpen]);

    const handleSendMessage = async (userMessage) => {
      const trimmedMessage = userMessage.trim();
      if (!trimmedMessage) return;
  
      setMessages(prevMessages => [...prevMessages, { sender: 'Usuario', text: trimmedMessage }]);
      setMessage(''); // Limpia el input del usuario
  
      // Buscar respuesta en las FAQs
      const faqMatch = faqs.find(faq => 
          faq.question.some(q => 
              q.toLowerCase() === trimmedMessage.toLowerCase()
          )
      );
  
      if (faqMatch) {
          const randomResponse = faqMatch.responses[Math.floor(Math.random() * faqMatch.responses.length)];
          setMessages(prevMessages => [...prevMessages, { sender: 'Cami', text: randomResponse.text }]);
          setQuickReplies(randomResponse.buttons || []);
          return;
      }
  
      // Si no hay coincidencias en FAQs, haz un fallback (como una llamada al backend)
      try {
          const res = await axios.post('http://127.0.0.1:5000/chat', { message: trimmedMessage });
          const responseText = res.data.response || res.data;
          const responseButtons = res.data.buttons || [];
          
          setMessages(prevMessages => [...prevMessages, { sender: 'Cami', text: responseText }]);
          setQuickReplies(responseButtons);
      } catch (error) {
          console.error("Error al enviar el mensaje:", error);
      }
  };

    // const handleSendMessage = async (userMessage) => {
    //     const trimmedMessage = userMessage.trim();
    //     if (!trimmedMessage) return;

    //     if (!name) {
    //         setName(trimmedMessage);
    //         setMessages(prevMessages => [
    //             ...prevMessages,
    //             { sender: 'Usuario', text: trimmedMessage },
    //             { sender: 'Cami', text: `Encantada de conocerte, ${trimmedMessage}. ¿Cómo te sientes hoy? ¿Puedo ayudarte con algún tipo de orientación o apoyo?` }
    //         ]);
    //         setQuickReplies([]); // Limpia las opciones de respuesta
    //         setMessage('');
    //         return;
    //     }

    //     setMessages(prevMessages => [...prevMessages, { sender: 'Usuario', text: trimmedMessage }]);
    //     setMessage(''); // Limpia el input del usuario
    //     setQuickReplies([]); // Limpia las opciones de respuesta

    //     try {
    //         const res = await axios.post('http://127.0.0.1:5000/chat', { message: trimmedMessage });
    //         const responseText = res.data.response || res.data; // Manejo de texto plano o con estructura
    //         const responseButtons = res.data.buttons || []; // Botones opcionales del backend

    //         setMessages(prevMessages => [...prevMessages, { sender: 'Cami', text: responseText }]);
    //         setQuickReplies(responseButtons); // Muestra las opciones de respuesta rápida
    //     } catch (error) {
    //         console.error("Error al enviar el mensaje:", error);
    //     }
    // };

    // const handleQuickReply = (reply) => {
    //     handleSendMessage(reply); // Envía la respuesta rápida seleccionada
    // };

    const handleQuickReply = (reply) => {
      handleSendMessage(reply); // Usa la misma lógica que un mensaje normal
  };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleSendMessage(message);
        }
    };

    return (
        <div className="fixed bottom-4 right-4">
            {/* {!isOpen && (
                <div className="relative" onClick={() => setIsOpen(true)}>
                    <div className="bg-purple-500 text-gray-700 p- rounded-full shadow-lg cursor-pointer flex items-center justify-center">
                    <img src={cami} className='w-20' />

                    </div>
                    <div className="absolute bottom-full mb-2 left-1/2 transform -translate-x-1/2 bg-white text-gray-700 p-2 rounded-lg shadow-lg text-sm">
                        ¿Necesitas ayuda?
                    </div>
                </div>
            )}

            {isOpen && (
                <div className="bg-white p-4 w-80 max-h-[500px] shadow-lg rounded-lg flex flex-col text-gray-600">
                    <div className="flex justify-between items-center mb-4">
                        <h3 className="text-lg font-bold text-gray-700">Chat con Cami</h3>
                        <button onClick={() => setIsOpen(false)} className="text-red-500 font-bold">X</button>
                    </div>

                    <div className="flex-1 overflow-y-auto mb-4">
                        {messages.map((msg, index) => (
                            <div
                                key={index}
                                className={`mb-2 ${
                                    msg.sender === 'Cami' ? 'text-left' : 'text-right'
                                }`}
                            >
                                <span
                                    className={`inline-block px-4 py-2 rounded-lg ${
                                        msg.sender === 'Cami'
                                            ? 'bg-purple-100 text-gray-700'
                                            : 'bg-purple-500 text-white'
                                    }`}
                                >
                                    {msg.text}
                                </span>
                            </div>
                        ))}
                        <div ref={messagesEndRef} />
                    </div>

                    <div className="mb-4">
                        {quickReplies.map((reply, index) => (
                            <button
                                key={index}
                                onClick={() => handleQuickReply(reply)}
                                className="bg-gray-200 text-gray-700 py-1 px-3 rounded-full shadow-md m-1 text-sm hover:bg-gray-300"
                            >
                                {reply}
                            </button>
                        ))}
                        
                    </div>

                    <div className="flex items-center">
                        <input
                            type="text"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            onKeyDown={handleKeyDown}
                            placeholder="Escribe tu mensaje..."
                            className="flex-1 px-4 py-2 border rounded-l-lg focus:outline-none focus:ring-2 focus:ring-purple-400"
                        />
                        <button
                            onClick={() => handleSendMessage(message)}
                            className="bg-purple-500 text-white px-4 py-2 rounded-r-lg hover:bg-purple-600"
                        >
                            Enviar
                        </button>
                    </div>
                </div>
            )} */}
        </div>
    );
}

export default ChatPopup;
