import React from 'react';
import niunamas from './images/niunamas.png';


const SoporteLegal = ({ title, content }) => {
  return (
    <div className="text-center text-xs font-sans">
        <div className='text-gray-400'>
          <h2 id="conoceme" className="text-2xl font-bold mb-4 mt-10 text-gray-400">¿Quiénes Somos?</h2>
            <p>
                Somos una organización comprometida con brindar apoyo a mujeres, niñas y adolescentes víctimas de violencia. Nuestra misión es educar, prevenir y acompañar en el proceso de recuperación.
            </p>
            <div className="mt-6">
                <h3 className="text-xl font-semibold">Aliados y patrocinadores</h3>
                <p>Empresas y organizaciones que nos apoyan en nuestra misión.</p>
            </div>
            <div className='text-sm text-black font-semibold flex text-center justify-center gap-10'>
            <h3 className='text-sm'>Diana Rodríguez <br /> Licenciada en Psicología</h3>
            <h3 className='text-sm'>Gabriela Sarabia <br /> Licenciada en Derecho</h3>
            </div>
            
          </div>
    </div>
  );
};

export default SoporteLegal;
