import React from 'react';
import Banner from './Banner';
import ChatBot from './Chatbot';
import SoportePsicologico from './SoportePsicologico';
import Cursos from './Cursos';
import Footer from './Footer';
import QuienesSomos from './QuienesSomos';
import SoporteLegal from './SoporteLegal';
import ApoyoPsicologico from './ApoyoPsicologico';
import '../index.css';
import '../index.postcss.css';
import Carrusel from './Carrusel';


const index = () => {
    return (
      // <div className='bg-gradient-to-tr from-black via-black to-violet-500 text-white'>
        <div className='text-gray-500' style={{ backgroundColor: '#f2e9e4', color: '#f2e9e4', fontFamily: 'Poppins, sans-serif' }}>
          
          <Banner />
          <QuienesSomos title="Quienes Somos?" content="Lista de cursos disponibles." />
          {/*<SoportePsicologico title="Algunas cosas que puedes hacer para" content="Frenar la violencia de género" />*/}
          
          <div className="flex w-auto mt-20 text-center justify-center">
            <p className="font-normal text-3xl mt-4 text-purple-500" style= {{ color: '#9b8bba'}}>
              ¡Esto te interesa!
            </p>
            
          </div>
            <Carrusel/>
            
            <SoporteLegal title="Apoyo Legal" content="Información sobre apoyo psicológico." />
            <ApoyoPsicologico title="Apoyo Psicologico" content="Información sobre apoyo psicológico." />
            <Cursos id="cooceme" title="Cursos para Mujeres" content="Lista de cursos disponibles." />
            
            <ChatBot id="" />
          <Footer id="contacto" />
        </div>
  
      
    );
  };
  
  export default index;
  