import React, { useState, useEffect } from 'react';
import escucha from './images/Reading.png';
import igualdad from './images/igualdaddegenero.png';
import educa from './images/Respond.png';

const SoportePsicologico = () => {
  const datosCarruselLegal = [
    {
      imagen: escucha,
      texto: "Proporcionamos asesoramiento legal para ayudar a las personas a navegar el sistema judicial."
    },
    // {
    //   imagen: igualdad,
    //   texto: "Ofrecemos refugios seguros para quienes están en situación de riesgo."
    // },
    {
      imagen: educa,
      texto: "Implementamos programas de capacitación y empoderamiento para fortalecer a las comunidades."
    }
  ];

  return (
    <div className="flex flex-col items-center justify-center p-1 font-sans">
      <div className="max-w-lg w-full" style={{ color: '#735d78' }}>
        <TextoCarrusel datos={datosCarruselLegal} />
      </div>
    </div>
  );
};

const TextoCarrusel = ({ datos }) => {
    const [indiceActual, setIndiceActual] = useState(0);
  
    useEffect(() => {
      const intervalo = setInterval(() => {
        setIndiceActual((prevIndice) => (prevIndice + 1) % datos.length);
      }, 5000); // Cambia la diapositiva cada 5 segundos
  
      return () => clearInterval(intervalo); // Limpiar el intervalo al desmontar el componente
    }, [datos]);
  
    const siguienteTexto = () => {
      setIndiceActual((prevIndice) => (prevIndice + 1) % datos.length);
    };
  
    const textoAnterior = () => {
      setIndiceActual((prevIndice) => (prevIndice - 1 + datos.length) % datos.length);
    };
  
    return (
      <div className="flex flex-col items-center justify-center p-1 border-dashed border-2 rounded-xl" style= {{ borderColor: '#9b8bba'}}>
        
        <img src={datos[indiceActual].imagen} alt={`Sección ${indiceActual + 1}`} className="w-48 h-48 mb-4" />
        <p className='text-base font-medium'>{datos[indiceActual].texto}</p>
      </div>
    );
  };

export default SoportePsicologico;
