import React, { useState } from 'react';
import Animacion from './AnimacionTest';
import pregunta1 from './images/test/cuestionario/pregunta1.jpg';
import pregunta2 from './images/test/cuestionario/pregunta2.jpg';
import pregunta3 from './images/test/cuestionario/pregunta3.jpg';
import pregunta4 from './images/test/cuestionario/pregunta4.jpg';
import pregunta5 from './images/test/cuestionario/pregunta5.jpg';
import pregunta6 from './images/test/cuestionario/pregunta6.jpg';
import pregunta7 from './images/test/cuestionario/pregunta7.jpg';
import pregunta8 from './images/test/cuestionario/pregunta8.jpg';
import amarillo from './images/test/cuestionario/AMARILLO.jpg';
import naranja from './images/test/cuestionario/NARANJA.jpg';
import rojo from './images/test/cuestionario/ROJO.jpg';

const questions = [
  {
    id: 1,
    text: "¿Tu pareja te dice palabras que te hacen sentir mal o realiza bromas hirientes hacia tu persona?",
    image: pregunta1,
  },
  {
    id: 2,
    text: "¿Tu ignora cuándo le hablas o te aplica la ley de hielo después de una discusión?",
    image: pregunta2,
  },
  {
    id: 3,
    text: "¿Te empujan, te avientan o usan algún tipo de violencia física?",
    image: pregunta3,
  },
  {
    id: 4,
    text: "¿Te expone frente a los demás con el objeto de humillarte o avergonzarte?",
    image: pregunta4,
  },
  {
    id: 5,
    text: "¿Quiere saber qué haces todo el tiempo y/o en que gastas el dinero?",
    image: pregunta5,
  },
  {
    id: 6,
    text: "¿Tu pareja revisa tus artículos personales celular, cartera, bolsa, ropa, coche, etc.?",
    image: pregunta6,
  },
  {
    id: 7,
    text: "¿Cuestiona tu apariencia o tu manera de ser dándote “consejos” de como serías mejor?",
    image: pregunta7,
  },
  {
    id: 8,
    text: "¿Te ha escondido o destruido algún objeto personal como: ropa, celular, maquillaje, documentos importantes, etc.?",
    image: pregunta8,
  },
];

const Test = () => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [score, setScore] = useState(0);
  const [results, setResults] = useState(null);

  const handleAnswer = (points) => {
    setScore(score + points);

    // Pasar a la siguiente pregunta o mostrar resultados
    const nextIndex = currentQuestionIndex + 1;
    if (nextIndex < questions.length) {
      setCurrentQuestionIndex(nextIndex);
    } else {
      evaluateResults();
    }
  };

  const evaluateResults = () => {
    let level;
    if (score <= 8) {
      level = "Nivel Amarillo: Precaución, puedes estar en una situación que requiere atención.";
    } else if (score <= 16) {
      level = "Nivel Naranja: Alerta, estás en una situación de riesgo.";
    } else {
      level = "Nivel Rojo: Peligro, busca ayuda inmediata.";
    }
    setResults(level);
  };

  const resetTest = () => {
    setScore(0);
    setCurrentQuestionIndex(0);
    setResults(null);
  };

  const getResultImage = () => {
    if (results.includes("Amarillo")) return amarillo; 
    if (results.includes("Naranja")) return naranja; 
    if (results.includes("Rojo")) return rojo; 
    return null;
  };

  return (
    <main className='h-auto bg-violet-200 grid place-items-center font-sans'>
      <div className=" text-center ">
            <p className="font-extrabold text-5xl   mt-4">
              Test violentómetro
            </p>
            
        </div>
        
      <section className='w-auto min-h-auto mt-2 grid lg:grid-cols-2 grid-cols-1 gap-10 [&>*:last-chils]:col-span-9 '>
        <aside className='  ml-8'>
          <p className='   m-8 text-lg text-justify'>
            No tengas miedo, aquí nadie te va a juzgar, por favor es de importancia que respondas con honestidas, para que 
            podamos apoyarte a pedir ayuda, TU ERES IMPORTANTE, NO TE QUEDES CALLADA, recuerda que NO ESTAS SOLA.
          </p>

          <Animacion title="Algunas cosas que puedes hacer para" content="Frenar la violencia de género" />
        </aside>

        <aside className='border-4 border-pink-600 m-8 rounded-xl'>

        {results ? (
          <div className="results text-center">
            <h1 className="text-lg text-center font-semibold m-4">Resultado: </h1>
            <p className="text-xl mx-4 font-bold  ">{results}</p>
              {getResultImage() && (
                <img
                  className="max-w-xs h-auto object-contain mx-auto my-4"
                  src={getResultImage()}
                  alt="Resultado relacionado"
                />
              )}
            <button className="w-1/2 px-4 py-2 bg-lime-500 text-black text-lg font-semibold rounded-xl text-center m-4" onClick={resetTest}>Reiniciar Test</button>
          </div>
        ) : (
          <div className="flex flex-col gap-2">
              <h2 className="text-xl text-center font-medium m-4">
                Pregunta {currentQuestionIndex + 1}:
              </h2>
              <p className="text-xl mx-4 font-semibold  ">{questions[currentQuestionIndex].text}</p>

              <img
                className="max-w-xs h-auto object-contain mx-auto mb-3"
                src={questions[currentQuestionIndex].image} 
                alt="Imagen descriptiva"
              />

              <div className="flex flex-col items-center gap-4 mb-4">
                <button
                  className="w-1/2 px-4 py-2 bg-red-300 text-black text-lg font-bold rounded-xl"
                  onClick={() => handleAnswer(4)}
                >
                  Sí
                </button>
                <button
                  className="w-1/2 px-4 py-2 bg-orange-400  text-black text-lg font-semibold rounded-xl"
                  onClick={() => handleAnswer(2)}
                >
                  A veces
                </button>
                <button
                  className="w-1/2 px-4 py-2 bg-sky-400 text-black text-lg font-bold rounded-xl"
                  onClick={() => handleAnswer(0)}
                >
                  No
                </button>
              </div>
            </div>
        )}
        </aside>
    </section>
    </main>
    
  );
};

export default Test;
