import React from 'react';
import Banner from './components/Banner';
import ChatBot from './components/Chatbot';
import SoportePsicologico from './components/SoportePsicologico';
import Cursos from './components/Cursos';
import Footer from './components/Footer';
import QuienesSomos from './components/QuienesSomos';
import SoporteLegal from './components/SoporteLegal';
import ApoyoPsicologico from './components/ApoyoPsicologico';
import './index.css';
import './index.postcss.css';
import 'bootstrap/dist/css/bootstrap.min.css';
//import ChatbotComponent from './components/ChatbotComponent';
//import Carrusel from './components/Carrusel';
import Test from './components/Test';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Inicio from './components/index';
import NumerosdeEmergencia from './components/NumerosdeEmergencia';

const App = () => {
  return (
    // <div className='bg-gradient-to-tr from-black via-black to-violet-500 text-white'>
    <BrowserRouter>
      <div className='flex-grow'>
        <Routes>
          <Route path="/" element={<Inicio />} />
          <Route path="/test" element={<Test />} />
          <Route path="/numeros-de-emergencia" element={<NumerosdeEmergencia />} />
        </Routes>
      </div>
    </BrowserRouter>
    
  );
};

export default App;
